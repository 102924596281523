export default (asyncCallsLoaded, hideFields, disabledFields, paramsFields, optionsFields) => {

  const cteDetails = [
    {
      id: 'labelCteEdit',
      tag: 'label',
      content: 'Detalhes da CTe'
    },
    {
      id: 'number',
      model: 'number',
      tag: 'InputGroup',
      props: {
        label: 'Número CTe',
        placeholder: 'Número CTe',
        readOnly: true,
      }
    },
    {
      id: 'series',
      model: 'series',
      tag: 'InputGroup',
      props: {
        label: 'Serie CTe',
        placeholder: 'Serie CTe',
        readOnly: true,
      }
    },
    {
      id: 'created_at',
      model: 'created_at',
      tag: 'BaseDatePicker',
      props: {
        label: 'Data de Emissão',
        format: 'DD/MM/YYYY HH:mm',
        type: 'datetime',
        prependIcon: false,
        range: false,
        placeholder: 'Data de Emissão',
        readOnly: true,
      }
    },
    {
      id: 'model',
      model: 'model',
      tag: 'InputGroup',
      props: {
        label: 'Tipo de CTe',
        placeholder: 'Tipo de CTe',
        readOnly: true,
      }
    },
    {
      id: 'status',
      model: 'status',
      tag: 'InputGroup',
      props: {
        label: 'Status',
        placeholder: 'Status',
        readOnly: true,
      }
    }
  ]

  const cteDetailsEdit = [
    {
      id: 'labelCteEditInfos',
      tag: 'label',
      content: '"Informações Editáveis"',
    },
    {
      id: 'cfop',
      model: 'cfop',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'CFOP',
        label: 'CFOP',
        placeholder: 'CFOP será inserido automaticamente',
        invalidLabel: 'O campo cfop precisa ser um número.',
        selectOptions: optionsFields.cfop,
        readOnly: true,
      },
    },
    {
      id: 'inscricao_estadual_remetente',
      model: 'inscricao_estadual_remetente',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Inscrição Estadual Remetente',
        placeholder: 'Inscrição Estadual Remetente',
        invalidLabel: 'O campo inscricao estadual emitente é obrigatório.',
        readOnly: disabledFields.cteDetailsEdit,
      },
    },
    {
      id: 'tipo_servico',
      model: 'tipo_servico',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Tipo de Serviço',
        label: 'Tipo de Serviço',
        placeholder: 'Selecione',
        invalidLabel: 'O campo tipo servico é obrigatório.',
        selectOptions: optionsFields.serviceType,
        readOnly: disabledFields.cteDetailsEdit,
      },
    },
    {
      id: 'tomador',
      model: 'tomador',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Tomador',
        label: 'Tomador',
        placeholder: 'Selecione',
        invalidLabel: 'O campo tomador é obrigatório.',
        selectOptions: optionsFields.taker,
        readOnly: disabledFields.cteDetailsEdit,
      },
    },
    {
      id: 'valor_receber',
      model: 'valor_receber',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Valor à receber',
        placeholder: 'Valor à receber',
        invalidLabel: 'O campo valor receber é obrigatório.',
        readOnly: disabledFields.cteDetailsEdit,
        mask: 'money'
      },
    },
    {
      id: 'uf_inicio',
      model: 'uf_inicio',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'UF Início',
        label: 'UF Início',
        placeholder: 'Selecione',
        invalidLabel: 'O campo uf inicio é obrigatório.',
        selectOptions: optionsFields.state,
        readOnly: disabledFields.cteDetailsEdit,
      },
      events: {
        ...(asyncCallsLoaded ? {input: 'changeUfStart'} : '')
      }
    },
    {
      id: 'municipio_inicio',
      model: 'municipio_inicio',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Município Início',
        label: 'Município Início',
        placeholder: 'Selecione',
        invalidLabel: 'O campo município início é obrigatório.',
        autoLoadRootOptions: false,
        selectOptions: null,
        fetchOptions: 'address/fetchCities',
        fetchParams: paramsFields.uf_inicio,
        disabled: disabledFields.ufInicio,
        readOnly: disabledFields.cteDetailsEdit
      },
    },
    {
      id: 'uf_fim',
      model: 'uf_fim',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'UF Fim',
        label: 'UF Fim',
        placeholder: 'Selecione',
        invalidLabel: 'O campo uf fim é obrigatório.',
        selectOptions: optionsFields.state,
        readOnly: disabledFields.cteDetailsEdit,
      },
      events: {
        ...(asyncCallsLoaded ? {input: 'changeUfEnd'} : '')
      }
    },
    {
      id: 'municipio_fim',
      model: 'municipio_fim',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Município Fim',
        label: 'Município Fim',
        placeholder: 'Selecione',
        invalidLabel: 'O campo município fim é obrigatório.',
        autoLoadRootOptions: false,
        selectOptions: null,
        fetchOptions: 'address/fetchCities',
        fetchParams: paramsFields.uf_fim,
        disabled: disabledFields.ufFim,
        readOnly: disabledFields.cteDetailsEdit
      },
    },
  ]

  const actions = [
    {
      id: 'cancel',
      tag: 'BaseButton',
      props: {
        label: 'Cancelar',
        color: 'black',
        textColor: 'white',
        filled: true,
        type: 'button',
      },
      events: {
        click: 'cancel',
      },
    },
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Salvar',
        color: 'black',
        textColor: 'white',
        filled: true,
        type: 'submit',
      },
    },
  ]
  
  return [
    ...!hideFields.cteDetails ? cteDetails : [],
    ...cteDetailsEdit,
    ...!hideFields.cteActions ? actions : [],
  ]
}