<template>
  <main class="CteDetailsEdit">
    <!-- Sub Header -->
    <TheSubHeader
      icon="package-delivery-alt"
      title="Editar CTe"
      :actions="['back']"
      organizer-gap="1rem"
    />
    <!--Content -->
    <section class="CteDetailsEdit__Container">
      <div class="CteDetailsEdit__Content">
        <div class="CteDetailsEdit__Form">
          <CteDetailsEditForm
            ref="cteDetailsEdit"
            @save="handlerSave"
          />
        </div>
      </div>
    </section>
    <CteManagementModal
      ref="modal"
      title="Edição de CTe"
      @cancel="cancel"
      @save="save"
    />
  </main>
</template>
<script>
import { TheSubHeader } from '@/components/organisms'
import CteDetailsEditForm from './Form/CteDetailsEditForm.vue'
import CteManagementModal from '../CteManagementModal.vue'

export default {
  name: 'CteDetailsEdit',
  components: {
    TheSubHeader,
    CteDetailsEditForm,
    CteManagementModal,
  },
  methods: {
    //Handlers
    handlerSave() {
      this.$refs.modal.open()
    },

    cancel() {
      this.$refs.cteDetailsEdit.setInitialForm()
    },

    save() {
      this.$refs.cteDetailsEdit.putCteDetailsEdit()
    },
  },
}
</script>
<style lang="scss" scoped>
.CteDetailsEdit {
  &__subHeaderTab {
    display: grid;
    grid-column-gap: 1rem;
    align-items: end;
    width: 100%;
  }

  &__Container {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: '. main .';
    grid-column-gap: 1rem;
  }

  &__Content {
    grid-area: main;
    margin-bottom: 50px;
  }

  &__Form {
    margin-bottom: 50px;
  }
}
</style>
