<template>
  <DynamicForm
    ref="form"
    class="CteDetails__Form__Fields"
    :style="style"
    :model="form"
    :blueprint="
      blueprint(
        asyncCallsLoaded,
        hideFields,
        disabledFields,
        paramsFields,
        optionsFields
      )
    "
    :endpoint-errors="endpointErrors"
    submit-type="json"
    @changeUfStart="handlerChangeUfStart"
    @changeUfEnd="handlerChangeUfEnd"
    @submit="handlerSubmit"
    @cancel="handlerCancel" 
  />
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { ctes } from '@/api'
import blueprint from './CteDetailsEditForm.blueprint'
import { cteStatus, populateForm } from '../../utils/utils.js'
import { DynamicForm } from '@/components/molecules'

export default {
  name: 'CteDetailsForm',
  components: {
    DynamicForm,
  },
  data() {
    return {
      form: {
        //Details 
        number: null,
        series: null,
        created_at: null,
        model: null,
        status: null,
        //Edit Info
        cfop: null,
        inscricao_estadual_remetente: null,
        tipo_servico: { id: 0, name: 'Normal' },
        tomador: { id: 1, name: 'Remetente' },
        valor_receber: null,
        municipio_inicio: null,
        uf_inicio: null,
        municipio_fim: null,
        uf_fim: null
      },

      form_initial: {},
      endpointErrors: {},

      //Helpers
      asyncCallsLoaded: false
    }
  },
  computed: {
    ...mapState({
      states: (state) => state.address.states,
      cities: (state) => state.address.cities
    }),

    //Route
    routeParams() {
      return {
        routeId: this.$route.params.route_id,
        cteId: this.$route.params.cte_id
      }
    },

    //Show and Hide
    hideFields() {
      return {
        cteDetails: this.form.status !== cteStatus.AUTORIZADO,
        cteActions: this.form.status === cteStatus.AUTORIZADO || this.form.status === cteStatus.PROCESSANDO
      }
    },

    //Disabled
    disabledFields() {
      return {
        cteDetailsEdit: this.form.status === cteStatus.AUTORIZADO || this.form.status === cteStatus.PROCESSANDO,
        ufInicio: !this.form.uf_inicio,
        ufFim: !this.form.uf_fim
      }
    },

    //Params
    paramsFields() {
      return {
        uf_inicio: this.form.uf_inicio ? this.form.uf_inicio.id : null,
        uf_fim: this.form.uf_fim ? this.form.uf_fim.id : null
      }
    },

    //Select Options
    optionsFields() {
      return { 
        cfop:[
          { id: '5353', name: '5353 – Prestação de serviço de transporte a estabelecimento comercial - Dentro do Estado de SP' },
          { id: '6353', name: '6353 – Prestação de serviço de transporte a estabelecimento comercial - Fora do Estado de SP' }
        ],
        serviceType: [
          { id: 0, name: 'Normal' },
          { id: 1, name: 'Subcontratação' },
          { id: 2, name: 'Redespacho' },
          { id: 3, name: 'Redespacho Intermediário' },
          { id: 4, name: 'Serviço Vinculado a Multimodal' }
        ],
        taker: [
          { id: 0, name: 'Emitente' },
          { id: 1, name: 'Remetente' },
          { id: 2, name: 'Expedidor' },
          { id: 3, name: 'Recebedor' },
          { id: 4, name: 'Destinatário' },
          { id: 5, name: 'Outros' }
        ],
        state: this.states,
      }
    },

    //Styles
    style: function() {
      return {
        '--grid-template':  this.formGridTemplateStyle
      }
    },

    formGridTemplateStyle() {
      const styleCteDetails = `'labelCteEdit labelCteEdit labelCteEdit . . . . . . . . .'
        'number number series series created_at created_at model model status status . .'`
      const styleCteDetailsEdit = `'labelCteEditInfos labelCteEditInfos labelCteEditInfos . . . . . . . . .'
        'cfop cfop cfop cfop cfop cfop cfop . . . . .'
        'inscricao_estadual_remetente inscricao_estadual_remetente inscricao_estadual_remetente tipo_servico tipo_servico tipo_servico tomador tomador tomador valor_receber valor_receber valor_receber'
        'uf_inicio uf_inicio uf_inicio municipio_inicio municipio_inicio municipio_inicio uf_fim uf_fim uf_fim municipio_fim municipio_fim municipio_fim'`
      const styleActions = '". . . . . . . . . . cancel confirm"'

      const style = (
        (!this.hideFields.cteDetails ? styleCteDetails : '') +
        styleCteDetailsEdit + 
        styleActions)
      return style
    }

  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      fetchStates: 'address/fetchStates',
      fetchCities: 'address/fetchCities',
      setLoading: 'loading/setLoading'
    }),

    //Form
    blueprint,

    //Init
    async init() {
      this.fetchStates()
      if (this.routeParams.cteId) await this.fetchCteDetails()
      this.getInitialForm()
      this.asyncCallsLoaded = true
    },

    //Calls
    async fetchCteDetails() {
      this.setLoading(true)
      await ctes.fetchCteDetails(
        { id: this.routeParams.cteId },
        (res) => {
          populateForm(res, this.form, this.form_initial)
          this.cfopFormField()
        },
        (e) => e,
        () => {
          this.setLoading(false)
        }
      )
    },

    //Actions
    async putCteDetailsEdit() {
      this.setLoading(true)
      await ctes.putCteDetailsEdit(
        { id: this.routeParams.cteId, body: this.form },
        () => {
          this.$router.push(`/routes/management/cte/${this.routeParams.routeId}`)
        },
        (e) => this.endpointErrors = e.validation,
        () => {
          this.setLoading(false)
        }
      )
    },

    //Handlers
    handlerCancel() {
      this.$router.push(`/routes/management/cte/${this.routeParams.routeId}`)
    },

    handlerSubmit() {
      this.$emit('save')
    },

    handlerChangeUfStart() {
      this.form.municipio_inicio = null
      this.$refs.form.$refs.municipio_inicio[0].copyOption(null)
      this.cfopFormField()
    },

    handlerChangeUfEnd() {
      this.form.municipio_fim = null
      this.$refs.form.$refs.municipio_fim[0].copyOption(null)
      this.cfopFormField()
    },

    //Helpers
    getInitialForm() {
      this.form_initial = Object.assign({}, this.form)
    },

    setInitialForm() {
      this.form = Object.assign({}, this.form_initial)
    },

    //Component Helpers
    cfopFormField() {
      let res = null
      if ((this.form.uf_inicio && this.form.uf_inicio.id === 25) && (this.form.uf_fim && this.form.uf_fim.id === 25)) {
        res = this.optionsFields.cfop.filter(el => el.id === '5353')
      } else if (
        (!!this.form.uf_inicio && !!this.form.uf_fim) && 
        ((this.form.uf_inicio && this.form.uf_inicio.id !== 25) || (this.form.uf_fim && this.form.uf_fim.id !== 25))
      ){
        res = this.optionsFields.cfop.filter(el => el.id === '6353')
      }
      this.form.cfop = res
    },

  },
}
</script>
<style lang="scss" scoped>
.CteDetails {
  &__Form {
    &__Fields {
      grid-template-areas: var(--grid-template);
      /deep/ .BaseButton {
        .BaseButton__Text {
          margin-left: 0;
        }
      }
    }
  }
}
</style>